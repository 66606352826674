/* eslint-disable */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import './styles.css';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  padding: "20px",
  margin: "0 !important",
  textAlign: 'center',
  borderRadius: 4,
}));

export default function BasicCard({ reloadOnFinish, handleStartTest, testIsRunning, handleReload, code }) {

  const handleCopyCode = () => navigator.clipboard.writeText(code);
  
  return (
    <Card sx={{ width: 310, margin: "20px" }}>
      <Stack
        direction="column"
        divider={
          <Box
            component="hr"
            sx={{ border:".1px solid #CCC", margin: "0 20px !important" }}
          />
        }
        spacing={2}
      >
        <Item><Typography>This test checks your browser settings for using the roclub app</Typography></Item>
        <Item>
          {!reloadOnFinish && <Button onClick={handleStartTest} variant='contained' disabled={testIsRunning}>Run test</Button>}
          {reloadOnFinish && <Button onClick={handleReload} variant='contained'>Run test again</Button>}
        </Item>
        {code !== "" && <Item>
          <Typography>Your test result code to share with roclub customer support:</Typography>
          <TextField
            type="text"
            id="CodeField"
            label=""
            sx={{ background: "#ccc", margin: "10px" }}
            value={code}
            disabled
          />
          <IconButton size="large" onClick={handleCopyCode} sx={{ mt: "15px" }} disabled={code === ""}>
            <ContentCopyIcon fontSize="medium" />
          </IconButton>
        </Item>}
      </Stack>
    </Card>
  );
}
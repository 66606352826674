import React, { useEffect, useState } from 'react';

const host = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

const Code = ({ codeParam }) => {
	const [result, setResult] = useState(null);

	useEffect(() => {
		const getCode = async () => {
			const response = await fetch(`${host}/api/v1/webrtc-test/tests/${codeParam}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Authorization': `Bearer 65d28fh1gwrhqwskjfw1`,
				}
			});
			const result = await response.json();
			setResult(result);
		}
		getCode();
	}, [codeParam]);

	return (
		<pre>
			{JSON.stringify(result, null, 2)}
		</pre>
	)
}

export default Code;
import React, { useState, useEffect, memo } from "react";
import randomString from 'crypto-random-string';
/* eslint-disable */
import { Typography, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import LoadingSpinner from '../LoadingSpinner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ArrowRight, Poor, Moderate, Good } from "../../assets/images/svgs";

const host = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

const SpeedTestS3 = ({ setTestFinished, startTest, uploadSpeed, setUploadSpeed, downloadSpeed, setDownloadSpeed, latency, setLatency }) => {
  const [presignedURL, setPresignedURL] = useState("");
  const [presignedURL2, setPresignedURL2] = useState("");

  useEffect(() => {
    if (startTest) {
      handleUpload();
      handleDownload();
      testLatency();
    }
  }, [startTest])

  useEffect(() => {
    if (uploadSpeed && downloadSpeed) setTestFinished(state => ({ ...state, speedtest: true }));
  }, [uploadSpeed, downloadSpeed])

  const handleDownload = async () => {
    try {
      // Fetch the presigned URL from the server
      const response = await fetch(`${host}/api/v1/webrtc-test/get-presigned-url-download`, {
        method: "GET",
      });
      const data = await response.json();
      const finalUrl = data.url;

      setPresignedURL(finalUrl);

      const startTime = Date.now();
        
      const res = await fetch(finalUrl, {
        method: "GET",
      });
      await res.blob();

      const endTime = Date.now();
      const downloadTime = endTime - startTime;
      const fileSizeInBytes = 10 * 1024 * 1024; // 10 MB
      console.log({ downloadTime })
      // const downloadSpeed = (file.size / downloadTime) * 1000; // Bytes per second
      // const downloadSpeed = (file.size * 8) / (downloadTime * 0.125); // MBits
      const downloadSpeed = (fileSizeInBytes * 8 / ((1 / Math.pow(10, 3)) * downloadTime)) / Math.pow(10, 6);
      setDownloadSpeed(downloadSpeed);

    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleUpload = async () => {
    try {
      // Fetch the presigned URL from the server
      const response = await fetch(`${host}/api/v1/webrtc-test/get-presigned-url-upload`, {
        method: "GET",
      });
      const data = await response.json();
      const finalUrl = data.url;
      setPresignedURL2(finalUrl);


      const generateRandomBlob = () => {
        const fileSizeInBytes = 10 * 1024 * 1024; // 10 MB
        const randomData = randomString({ length: fileSizeInBytes });
        return randomData;
      };
    
      const randomBlobData = generateRandomBlob();

      if (randomBlobData) {

        const file = new File([randomBlobData], "uploaded_image.jpg", {
          type: "image/jpeg", // Change the type accordingly based on your image format
        });

        console.log("file size: " + file.size)

        // Measure the upload speed
        const startTime = Date.now();
        const formData = new FormData();
        formData.append("file", file);

        // Perform the file upload directly to S3 using the presigned URL
        await fetch(finalUrl, {
          method: "PUT",
          body: file,
        });

        const endTime = Date.now();
        const uploadTime = endTime - startTime;
        console.log({ uploadTime })
        // const uploadSpeed = (file.size / uploadTime) * 1000; // Bytes per second
        // const uploadSpeed = (file.size * 8) / (uploadTime * 0.125); // MBits
        const uploadSpeed = (file.size * 8 / ((1 / Math.pow(10, 3)) * uploadTime)) / Math.pow(10, 6);
        /**
         * Upload-Geschwindigkeit in Mbps = (Dateigröße in Bytes * 8) / (Upload-Zeit in ms * 0.125)
         */
        setUploadSpeed(uploadSpeed);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const testLatency = async () => {
    const latencyArray = [];


    for (let i = 0; i < 10; i++) {
      const startTime = new Date().getTime();
      try {
        await fetch(`${host}/api/v1/webrtc-test/latency`);
        const endTime = new Date().getTime();
        const latency = endTime - startTime;
        latencyArray.push(latency);
        await timeout(300);
      } catch (error) {
        setLatency("Error");
        console.error("Error during latency test:", error);
      }
    }

    console.log("latencyArray:", JSON.stringify(latencyArray));

    // Sort the array
    const sortedLatencies = latencyArray.sort((a, b) => a - b);

    // Calculate the median
    let median;
    const mid = Math.floor(sortedLatencies.length / 2);

    if (sortedLatencies.length % 2) {
      median = sortedLatencies[mid];
    } else {
      median = (sortedLatencies[mid - 1] + sortedLatencies[mid]) / 2;
    }

    
    // Average latency without outliers > 1000ms
    const filteredLatencyArray = latencyArray.filter(latency => latency < 1000);
    const sum = filteredLatencyArray.reduce((a, b) => a + b, 0);
    const average = sum / filteredLatencyArray.length;
    console.log("Latency:", { median, average });

    setLatency(median);
  };

  const handleDownloadIcon = () => {
    let Icon = <></>;
    if (downloadSpeed) {
      const val = Math.round(downloadSpeed);
      if (val < 15) { Icon = <Poor />} else
      if (val < 40) { Icon = <Moderate />} else
                    { Icon = <Good />}
    }
    return Icon;
  }

  const handleUploadIcon = () => {
    let Icon = <></>;
    if (uploadSpeed) {
      const val = Math.round(uploadSpeed);
      if (val < 10) { Icon = <Poor />} else
      if (val < 15) { Icon = <Moderate />} else
                    { Icon = <Good />}
    }
    return Icon;
  }

  const handleLatencyIcon = () => {
    let Icon = <></>;
    if (latency) {
      const val = Math.round(latency);
      if (val > 140) { Icon = <Poor />} else
      if (val > 25) { Icon = <Moderate />} else
                    { Icon = <Good />}
    }
    return Icon;
  }

  return (
    <Accordion>
      <AccordionSummary
          expandIcon={<ArrowRight />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          >
      <Typography>Internet Quality</Typography>
      <div style={{ marginLeft: 'auto' }}>
        {uploadSpeed > 10 && downloadSpeed > 40 && <CheckIcon sx={{ color: "green" }}/>}
        {uploadSpeed && downloadSpeed && (uploadSpeed < 10 || downloadSpeed < 40)  && <CloseIcon sx={{ color: "red" }}/>}
        {(!uploadSpeed || !downloadSpeed) && presignedURL !== "" && presignedURL2 !== "" && <LoadingSpinner />}
      </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
          <Typography className="accordion-item">Download</Typography>
          <Typography className="accordion-item">{downloadSpeed !== null && `${downloadSpeed.toFixed(2)} MBits`}</Typography>
          <Typography>{handleDownloadIcon()}</Typography>
          <Divider sx={{ width: "100%" }}/>
          <Typography className="accordion-item">Upload</Typography>
          <Typography className="accordion-item">{uploadSpeed !== null && `${uploadSpeed.toFixed(2)} MBits`}</Typography>
          <Typography>{handleUploadIcon()}</Typography>
          <Divider sx={{ width: "100%" }}/>
          <Typography className="accordion-item">Latency</Typography>
          <Typography className="accordion-item">{latency === "Error" ? "Error" : latency === null ? "" : latency + " ms"}</Typography>
          <Typography>{handleLatencyIcon()}</Typography>
        </Grid>
        <div className="info">
          <span className="info-icon"><InfoOutlinedIcon /></span>
          <Typography variant="body2" display="block">
            {`The bandwidth required to use the app depends on the extent to which you want to conduct remote sessions.As a local operator, the following internet bandwidth is recommended: Download: > 25 MBit/s; Upload > 10 MBit/s.`}
          </Typography>
          <br />
          <Typography variant="body2" display="block">
            {`As a remote operator, the following internet bandwidth is recommended depending on the sessions to be conducted simultaneously: 1 session: download: > 25 MBit/s; Upload > 10 MBit/s 2 parallel sessions: Download: > 50 MBit/s; upload > 20 MBit/s 3 parallel sessions: Download: > 75 MBit/s; Upload > 30 MBit/s`}
          </Typography>
          <br />
          <Typography variant="body2" display="block">
            {`For smooth operation of the app, a latency < 10 ms is recommended. The use of a WLAN connection usually has a negative effect on the latency.`}
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>

  );
};

export default memo(SpeedTestS3);

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import { Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoadingSpinner from '../LoadingSpinner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Divider from '@mui/material/Divider';
import { Accessible, ArrowRight, NotAccessible } from '../../assets/images/svgs';


const STUNTest = ({ startTest, setTestFinished, isStunReachable, setIsStunReachable, isTurnReachable, setIsTurnReachable }) => {

  useEffect(() => {
    if (!startTest) return;
    const candidateTypes = [];
    const iceServers = [
      // Test some STUN server
      {
        urls: 'stun:fr-turn1.xirsys.com'
      },
      // Test some TURN server
      // {
      //     urls: "stun:fr-turn1.xirsys.com", 
      //     username: "9SuTSp9-PNPr1X9pk5Fu-_NSxJJFBgyUuXVtTjh8hir_YoIew15lGj2FB0V7lPoNAAAAAGM0bTVkYW5pZWw4MDg=",
      //     credential: '3d4bb42c-3f45-11ed-a862-0242ac120004'
      // }
    ];

    const pc = new RTCPeerConnection({ iceServers });

    const gatherIceCandidates = () => {
      return new Promise(resolve => {
        pc.onicecandidate = e => {
          if (e.candidate === null) return resolve();

          // Display candidate string e.g
          // candidate:842163049 1 udp 1677729535 XXX.XXX.XX.XXXX 58481 typ srflx raddr 0.0.0.0 rport 0 generation 0 ufrag sXP5 network-cost 999
          console.log(e.candidate.candidate);

          // If a srflx candidate was found, notify that the STUN server works!
          if (e.candidate.type === 'srflx') {
            console.log('The STUN server is reachable!');
            console.log(`   Your Public IP Address is: ${e.candidate.address}`);
          }
          // If a relay candidate was found, notify that the TURN server works!
          if (e.candidate.type === 'relay') {
            console.log('The TURN server is reachable !');
          }
          candidateTypes.push(e.candidate.type);
        };
      });
    };

    const startPeerConnection = async () => {
      await gatherIceCandidates();
      console.log({ candidateTypes });

      // Display results
      if (candidateTypes.includes('srflx')) {
        setIsStunReachable(true);
        setTestFinished(state => ({ ...state, stuntest: true }));
        console.log('The STUN server is reachable!');
    } else {
        setIsStunReachable(false);
        setTestFinished(state => ({ ...state, stuntest: true }))
        console.log('The STUN server is NOT reachable!');
      }

      /*
      if (candidateTypes.includes('relay')) {
        setIsTurnReachable(true);
        console.log('The TURN server is reachable!');
    } else {
        setIsTurnReachable(false);
        console.log('The TURN server is NOT reachable!');
      }
      */
    };

    startPeerConnection(pc);

    // Log errors:
    // Remember that in most cases, even if it's working, you will find a STUN host lookup received error
    // Chrome tried to look up the IPv6 DNS record for the server and got an error in that process.
    // However, it may still be accessible through the IPv4 address
    pc.onicecandidateerror = e => {
      console.error(e);
    };

    pc.createDataChannel('roclub-rocks');
    pc.createOffer().then(offer => pc.setLocalDescription(offer));

    // Cleanup
    return () => {
      pc.close();
    };
  }, [startTest]);


  return (
    <Accordion>
      <AccordionSummary
          expandIcon={<ArrowRight />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          >
      <Typography>Relay Server Access for Video Streams</Typography>
      <div style={{ marginLeft: 'auto' }}>
        {isStunReachable === true && <CheckIcon sx={{ color: "green" }}/>}
        {isStunReachable === false && <CloseIcon sx={{ color: "red" }}/>}
        {isStunReachable === null && startTest === true && <LoadingSpinner />}
      </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
        <Typography className="accordion-item">STUN server</Typography>
          {isStunReachable === true && <Accessible />}
          {isStunReachable === false && <NotAccessible />}
        </Grid>
        {/* <Divider />
        <Typography className="accordion-item">TURN server</Typography>
        <Divider /> */}

        <div className="info">
          <span className="info-icon"><InfoOutlinedIcon /></span>
          <Typography variant="body2" display="block">
            You get the best performance in remote control streaming and videoconferencing if your network allows communication via a STUN server. 
          </Typography>
          <br />
          <Typography variant="body2" display="block">
            If only one TURN server is available, you will have higher latency.
          </Typography>
          <br />
          <Typography variant="body2" display="block">
            If neither a STUN server nor a TURN server is available, the remote control stream and the videoconference will not work. In this case, please contact your system administrator to check the firewall settings.
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default STUNTest;

/* eslint-disable */
import React, { lazy, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import STUNTest from '../../components/Stun';
import SpeedTestS3 from '../../components/SpeedTestS3';
import CamMicTest from '../../components/CamMic';
import './styles.css';
import VideoResolutionAccordion from '../../components/VideoResolutionAccordion';
import { Button, Box, Grid, Stack } from '@mui/material';
// import PingTest from '../../components/Domain';
import Run from '../../components/Run';
import Code from '../Code';

const host = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

const TestPage = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const codeParam = queryParameters.get("code")
  const [startTest, setStartTest] = useState(false);
  const [testIsRunning, setTestIsRunning] = useState(false);
  const [reloadOnFinish, setReloadOnFinish] = useState(false);
  const [code, setCode] = useState("");
  const [testFinished, setTestFinished] = useState({
    cammic: false,
    stuntest: false,
    speedtest: false,
  });
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [latency, setLatency] = useState(null);
  const [isStunReachable, setIsStunReachable] = useState(null);
  const [isTurnReachable, setIsTurnReachable] = useState(null);
  const [permissionGranted, setPermissionGranted] = useState({ audio: false, video: false });

  const handleStartTest = async () => setStartTest(true);

  useEffect(() => {
    // test started, disable button
    if (startTest) setTestIsRunning(true);

    // test finished, reset all values
    if (startTest && !Object.values(testFinished).includes(false)) {
      setReloadOnFinish(true);
      console.log("test is finished")
      saveTest();
      // setStartTest(false);
      // setTestIsRunning(false);
      // setTestFinished(() => ({
      //   cammic: false,
      //   stuntest: false,
      //   videoresolution: false,
      //   speedtest: false,
      // }))
    }
  }, [testFinished, startTest])

  const handleReload = () => location.reload();

  const saveTest = async () => {
    const data = {
      latency: `${latency} ms`,
      upload: `${uploadSpeed.toFixed(2)} MBits`,
      download: `${downloadSpeed.toFixed(2)} MBits`,
      stun: isStunReachable ? "reachable" : "not reachable",
      camera: permissionGranted.video,
      microphone: permissionGranted.audio,
    };
    try {
      const response = await fetch(`${host}/api/v1/webrtc-test/tests`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer 65d28fh1gwrhqwskjfw1`,
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      setCode(result.Payload.code)
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <>
    {codeParam === null ?
      <Box m={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center">
          <Run reloadOnFinish={reloadOnFinish} handleStartTest={handleStartTest} testIsRunning={testIsRunning} handleReload={handleReload} code={code}/>
          <div id="Accordion">
            <SpeedTestS3 setTestFinished={setTestFinished} startTest={startTest} uploadSpeed={uploadSpeed} setUploadSpeed={setUploadSpeed} downloadSpeed={downloadSpeed} setDownloadSpeed={setDownloadSpeed} latency={latency} setLatency={setLatency}/>
            <STUNTest setTestFinished={setTestFinished} startTest={startTest} isStunReachable={isStunReachable} setIsStunReachable={setIsStunReachable} isTurnReachable={isTurnReachable} setIsTurnReachable={setIsTurnReachable}/>
            <CamMicTest setTestFinished={setTestFinished} startTest={startTest} permissionGranted={permissionGranted} setPermissionGranted={setPermissionGranted}/>
            {/* <VideoResolutionAccordion setTestFinished={setTestFinished} startTest={startTest}/> */}
            {/* <PingTest /> */}
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Bandwidth</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {bandwidthTested && "Bandwidth test completed"}
                </Typography>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </Stack>
      </Box>
      :
      <Code codeParam={codeParam} />
    }
    </>
  );
};

export default TestPage;

import React, { useEffect, useState } from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LoadingSpinner from '../LoadingSpinner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ArrowRight, Granted, NotGranted } from '../../assets/images/svgs';

const CamMic = ({ startTest, setTestFinished, permissionGranted, setPermissionGranted }) => {
	const [camMicFinished, setCamMicFinished] = useState(false);

	const checkPermissions = async () => {
		try {
			const streamAudio = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
			streamAudio.getTracks().forEach((track) => track.stop());
			setPermissionGranted(state => ({ ...state, audio: true }));
		} catch (error) {
			setPermissionGranted(state => ({ ...state, audio: false }));
			console.error('Error accessing microphone:', error);
		}
		try {
			const streamVideo = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
			streamVideo.getTracks().forEach((track) => track.stop());
			setPermissionGranted(state => ({ ...state, video: true }));
		} catch (error) {
			setPermissionGranted(state => ({ ...state, video: false }));
			console.error('Error accessing camera:', error);
		}
		setCamMicFinished(true);
	};

	useEffect(() => {
		if (startTest) checkPermissions();
	}, [startTest]);

	useEffect(() => {
		if (camMicFinished === true) setTestFinished(state => ({ ...state, cammic: true }));
	}, [camMicFinished, setTestFinished]);

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ArrowRight />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography>Camera and Microphone Access</Typography>
				<div style={{ marginLeft: 'auto' }}>
					{(permissionGranted.audio && permissionGranted.video) && <CheckIcon sx={{ color: "green" }}/>}
					{camMicFinished && (permissionGranted.audio === false || permissionGranted.video === false) && <CloseIcon sx={{ color: "red" }}/>}
					{startTest === true && !camMicFinished && <LoadingSpinner />}
				</div>
			</AccordionSummary>
			<Divider />
			<AccordionDetails>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Typography className="accordion-item">Camera permissions</Typography>
					{startTest && camMicFinished && (permissionGranted.video ? <Granted /> : <NotGranted />)}
					<Divider sx={{ width: "100%" }} />
					<Typography className="accordion-item">Microphone permissions</Typography>
					{startTest && camMicFinished && (permissionGranted.audio ? <Granted /> : <NotGranted />)}
				</Grid>
				<div className="info">
					<span className="info-icon"><InfoOutlinedIcon /></span>
					<Typography variant="body2" display="block">
						If the permission to the camera and microphone is not granted, please go to the settings in your browser and enable the camera or microphone.
					</Typography>
				</div>
			</AccordionDetails>
		</Accordion>
	)
}

export default CamMic;
import React from 'react';
import logo from '../../assets/images/logo.png';
import './styles.css';

const TestPage = () => {

    return (
        <>
            <div id="Header">
                <img src={logo} className="logo" alt="roclub logo"/>
                <h1>Network & Setup Test for roclub App</h1>
            </div>
        </>
    )
}

export default TestPage;